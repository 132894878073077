import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from '@tanstack/react-query';

import * as Sentry from "@sentry/react";

//
import App from './App';
import queryClient from './utils/query-client';
import { SENTRY_DSN } from './config-global';


// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [],
  environment: process.env.NODE_ENV
});

root.render(
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Suspense>
          <App />
        </Suspense>
      </BrowserRouter>
    </QueryClientProvider>
  </HelmetProvider>
);
